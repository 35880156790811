import React from 'react';
import { StatusHistoryCodeFilters } from '../../../../app/models/StatusHistory';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: StatusHistoryCodeFilters;
    setFilter: (filter: StatusHistoryCodeFilters) => void;
    onSearch: () => void;
};

const StatusHistoryCodeFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="Item">
                            Código
                        </label>
                        <input
                            title="Item"
                            type="text"
                            id="id"
                            name="id"
                            className="form-control form-control-sm"
                            value={filter.id}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="Item">
                            Estado
                        </label>
                        <input
                            title="Estado"
                            type="text"
                            id="name"
                            name="name"
                            className="form-control form-control-sm"
                            value={filter.name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="object_name">
                            Item
                        </label>
                        <input
                            title="Item"
                            type="text"
                            id="object_name"
                            name="object_name"
                            className="form-control form-control-sm"
                            value={filter.object_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatusHistoryCodeFilter;
