import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useSweetAlert from '../../hooks/useSweetAlert';
import useTransportOrderService from '../../app/services/hooks/useTransportOrderService';
import {
    TransportOrder,
    TransportOrderDataForm,
    TransportOrderFilters
} from '../../app/models/TransportOrder';
import { TransportationOrderStatus } from '../../app/models/TransportationOrderStatus';
import { useHistory } from 'react-router-dom';
import TransportOrdersDataTable from '../WorkOrders/components/tables/TransportOrderDataTable';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import { toast } from 'react-toastify';
import useNavigationPage from '../../hooks/useNavigationPage';
import { AuthContext } from '../../contexts/AuthContext';
import ButtonEditColumns from '../../components/buttons/ButtonEditColumns';
import DefaultModal from '../../components/default/DefaultModal';
import ColumnGroupCreate from '../Columns/subcomponents/ColumnGroupCreate';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Ordenes de transporte',
        url: '/Transport-orders',
        isActive: true
    }
];

const TransportOrders = () => {
    const { auth } = useContext(AuthContext);
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const {
        fetchingGetTransportOrders,
        getTransportOrders,
        deleteTransportOrder,
        fetchingGetExcelTransportOrders,
        getExcelAllTransportOrders
    } = useTransportOrderService();
    const [transportOrders, setTransportOrders] = useState<TransportOrderDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [statuses, setStatuses] = useState<TransportationOrderStatus[]>([]);
    const { navigationPage } = useNavigationPage();

    const [transportOrderIdEdit, setTransportOrderIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);

    const [columnAlias, setColumnAlias] = useState<string[]>([]);
    const [editColumns, setEditColumns] = useState(false);

    const [filter, setFilter] = useState<TransportOrderFilters>({
        page: 1,
        per_page: 10,
        sort: 'program_date',
        order: 'desc',
        table_name: 'transport_order'
    });

    useEffect(() => {
        getAllTransportOrders();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);

    const getAllTransportOrders = () => {
        getTransportOrders(filter, {
            onSuccess: (response: ServiceResponse) => {
                setTransportOrders(response.data.transport_orders);
                setStatuses(response.data.statuses ?? []);
                setTotalRows(response.data.total_rows);
                setColumnAlias(response.data.alias_list)
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showEdit = (TransportOrderId: number) => {
        setShowingEdit(true);
        setTransportOrderIdEdit(TransportOrderId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setTransportOrderIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllTransportOrders();
    };

    const showExpense = (transportOrderId: number) => {
        navigationPage('/transport-orders/' + transportOrderId + '/edit');
    };
    const destroy = (transportOrderId: number) => {
        const _text = 'Está a punto de eliminar el origen/destino #' + transportOrderId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading)
                            showLoading('loading', 'Eliminando orden de transporte...');
                        deleteTransportOrder(transportOrderId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La orden de transporte no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const downloadExcel = () => {
        if (showLoading) showLoading('download', 'Recuperando documento...');
        getExcelAllTransportOrders(filter, {
            onSuccess: (message: string) => {
                if (changeAnimation) changeAnimation('downloadSuccess', message, true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    
    const showEditColumns = () => {
        setEditColumns(true);
    };

    const hideEditColumns = () => {
        setEditColumns(false);
    };

    return (
        <>
            <Breadcrumbs pageSection="Ordenes de transporte" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                {auth &&
                auth?.roles &&
                auth?.roles.includes('Administrador') &&
                    (<div className="col d-flex justify-content-end">
                        <ButtonEditColumns
                            callbackCreate={showEditColumns}
                            title="Editar Columnas"
                        />
                    </div>)
                }
                {transportOrders ? (
                    <TransportOrdersDataTable
                        transportOrders={transportOrders}
                        updateExpenses={showExpense}
                        loading={fetchingGetTransportOrders}
                        totalRows={totalRows}
                        downloadExcel={downloadExcel}
                        statuses={statuses}
                        filter={filter}
                        setFilter={setFilter}
                        onSearch={getAllTransportOrders}
                        columnDict={columnAlias}
                    />
                ) : null}
            </DefaultCard>
            {editColumns ? (
                <DefaultModal
                    show={editColumns}
                    handleClose={hideEditColumns}
                    title="Editar columnas de la tabla"
                    backdrop={true}
                    showFooter={false}
                >
                    <ColumnGroupCreate
                        tableName={filter.table_name!}
                        onSaved={() => {
                            reloadTable();
                            hideEditColumns();
                        }}
                        onCancel={hideEditColumns}
                        onError={hideCreate}                     
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default TransportOrders;
