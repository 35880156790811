import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { StatusHistoryCodeFilters, StatusHistoryFilters } from '../../models/StatusHistory';

const useStatusHistory = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetStatusHistories, setFetchingGetStatusHistories] = useState(false);
    const [fetchingGetStatusHistoryCodes, setFetchingGetStatusHistoriyCodes] = useState(false);

    const getStatusHistories = (
        statusHistoryFilters: StatusHistoryFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(statusHistoryFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.STATUS_HISTORIES.GET_STATUS_HISTORIES}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetStatusHistories
        });
    };

    const getStatusHistoryCodes = (
        filter: StatusHistoryCodeFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.STATUS_HISTORIES.GET_STATUS_HISTORY_CODES}?${queryString}`;

        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetStatusHistoriyCodes
        });
    };

    return {
        fetchingGetStatusHistories,
        getStatusHistories,
        fetchingGetStatusHistoryCodes,
        getStatusHistoryCodes
    };
};

export default useStatusHistory;
