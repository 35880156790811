import ButtonCancelForm from "../../../components/buttons/ButtonCancelForm";
import ButtonSaveForm from "../../../components/buttons/ButtonSaveForm";
import useColumnService from "../../../app/services/hooks/useColumnService";
import { useEffect, useContext, useState } from "react";
import { AppContext } from '../../../contexts/AppContext';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import { Column, ColumnGroupDataForm, defaultColumnGroupDataForm } from "../../../app/models/Column";
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ColumnGroupForm from "../forms/ColumnGroupForm";
import { Group } from "../../../app/models/Group";


interface Props {
    tableName: string
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ColumnGroupCreate = ({ tableName, onSaved, onCancel, onError }: Props) => {

    const { showLoading, hideLoading } = useContext(AppContext);
    const { createColumnGroup, 
        storeColumnGroup, 
        getColumnsByTableAndRole,
        fetchingGetColumnsByTableAndRole,
        fetchingCreateColumnGroup, 
        fetchingStoreColumnGroup 
    } = useColumnService()
    const [ errorFields, setErrorFields ] = useState<any>();
    const [ columnGroup, setColumnGroup ] = useState<ColumnGroupDataForm>(defaultColumnGroupDataForm);
    const [ columns, setColumns ] = useState<Column[]>([])
    const [ groups, setGroups ] = useState<Group[]>([])

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createColumnGroup(tableName, {
            onSuccess: (response: ServiceResponse) => {
                setColumns(response.data.columns);
                setGroups(response.data.groups);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    useEffect(() => {
        if(columnGroup.group !== -1) searchColumnsByRole();
    }, [columnGroup.group])

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando grupo de columnas...');
        storeColumnGroup(columnGroup, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const searchColumnsByRole = () => {
        if (showLoading) showLoading('loading', 'Guardando grupo de columnas...');
        getColumnsByTableAndRole(tableName, columnGroup.group, {
            onSuccess: (response: ServiceResponse) => {
                const columns = response.data.columns

                setColumnGroup({
                    ...columnGroup,
                    columns: columns.map((item: any) => ({
                        value: item.id,
                        label: item.alias
                    }))
                });

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateColumnGroup ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <ColumnGroupForm 
                        columnGroupForm={columnGroup} 
                        setColumnGroupForm={setColumnGroup} 
                        columns={columns} 
                        groups={groups}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreColumnGroup} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreColumnGroup} />
                </div>
            </div>
        </>
    );
};

export default ColumnGroupCreate;