import React from 'react';
import MonthYearPicker from '../../../../components/inputs/MonthYearPicker';
import { momentParseDate } from '../../../../helpers';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: any;
    setFilter: (filter: any) => void;
    onSearch: () => void;
    columnDict: string[]
};

const WorkOrderFilter = ({ filter, setFilter, onSearch, columnDict }: Props) => {
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                {columnDict.includes('Sociedad') && <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="first_name">
                            Sociedad
                        </label>
                        <input
                            title="Sociedad"
                            type="text"
                            id="society_name"
                            name="society_name"
                            className="form-control form-control-sm"
                            value={filter.society_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>}
                {columnDict.includes('Patente Tracto') && <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="truck_patent">
                            Patente Tracto
                        </label>
                        <input
                            title="Patente Tracto"
                            type="text"
                            id="truck_patent"
                            name="truck_patent"
                            className="form-control form-control-sm"
                            value={filter.truck_patent}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>}
                {columnDict.includes('Marca Tracto') && <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="truck_brand_name">
                            Marca Tracto
                        </label>
                        <input
                            title="Marca Tracto"
                            type="text"
                            id="truck_brand_name"
                            name="truck_brand_name"
                            className="form-control form-control-sm"
                            value={filter.truck_brand_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>}

                {columnDict.includes('Modelo Tracto') && <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="truck_model_vehicle_name">
                            Modelo Tracto
                        </label>
                        <input
                            title="Modelo Tracto"
                            type="text"
                            id="truck_model_vehicle_name"
                            name="truck_model_vehicle_name"
                            className="form-control form-control-sm"
                            value={filter.truck_model_vehicle_name}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>}

                {columnDict.includes('Periodo') && <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="period_from">
                            Período desde
                        </label>
                        <MonthYearPicker
                            className="form-control form-control-sm input-datepicker"
                            onMonthYearChange={(date) =>
                                handlerInputFilter({
                                    target: {
                                        name: 'period_from',
                                        value: String(momentParseDate(date, 'YYYY-MM-DD'))
                                    }
                                })
                            }
                        />
                    </div>
                </div>}
                {columnDict.includes('Periodo') && <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="period_until">
                            Período hasta
                        </label>
                        <MonthYearPicker
                            className="form-control form-control-sm input-datepicker"
                            onMonthYearChange={(date) =>
                                handlerInputFilter({
                                    target: {
                                        name: 'period_until',
                                        value: String(momentParseDate(date, 'YYYY-MM-DD'))
                                    }
                                })
                            }
                        />
                    </div>
                </div>}
                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkOrderFilter;
