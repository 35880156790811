import React from 'react';
import { toast } from 'react-toastify';

type Props = {
    callbackCreate: () => void;
    title?: string;
    loading?: boolean;
    locked?: boolean;
    message?: string;
};

const ButtonEditColumns = ({
    callbackCreate,
    title = 'Nuevo',
    loading = false,
    locked = false,
    message = 'No se puede crear un registro.'
}: Props) => {
    const showLockedMessage = () => {
        toast.error(message);
    };

    return !loading ? (
        <button
            type="button"
            className="waves-effect btn btn-outline-primary btn-sm"
            onClick={locked ? showLockedMessage : callbackCreate}
        >
            <i className="fa-solid fa-table-columns" /> {title}
        </button>
    ) : (
        <button type="button" className="waves-effect btn btn-outline-primary btn-sm disabled">
            <i className="fa-solid fa-table-columns" /> {title}
        </button>
    );
};

export default ButtonEditColumns;
