import React, { useContext } from 'react';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { QuoteStatus } from '../../../../app/models/QuoteStatus';
import { momentParseDateTime } from '../../../../helpers';
import { StatusHistory, StatusHistoryFilters } from '../../../../app/models/StatusHistory';
import StatusHistoryFilter from './StatusHistoryFilter';

interface Props {
    statusHistories: StatusHistory[];
    totalRows: number;
    loading?: boolean;
    filter: StatusHistoryFilters;
    setFilter: (filter: StatusHistoryFilters) => void;
    paginationRowsPerPageOptions?: any[];
    onSearch: () => void;
}

const StatusHistoryDataTable = ({
    statusHistories,
    totalRows,
    filter,
    setFilter,
    loading = false,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: StatusHistory, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Codigo anterior',
            selector: (row: any) => row.before_status_code,
            sortable: true,
            sortField: 'before_status_code',
            cell: (row: StatusHistory, index: any, column: any) => (
                <span className="">{row.before_status_code}</span>
            )
        },
        {
            name: 'Estado anterior',
            selector: (row: any) => row.before_status_name,
            sortable: true,
            sortField: 'before_status_name',
            cell: (row: StatusHistory, index: any, column: any) => (
                <span className="">{row.before_status_name}</span>
            )
        },
        {
            name: 'Codigo nuevo',
            selector: (row: any) => row.new_status_code,
            sortable: true,
            sortField: 'new_status_code',
            cell: (row: StatusHistory, index: any, column: any) => (
                <span className="">{row.new_status_code}</span>
            )
        },
        {
            name: 'Estado nuevo',
            selector: (row: any) => row.new_status_name,
            sortable: true,
            sortField: 'new_status_name',
            cell: (row: StatusHistory, index: any, column: any) => (
                <span className="">{row.new_status_name}</span>
            )
        },
        {
            name: 'Item',
            selector: (row: any) => row.obj_label,
            sortable: true,
            sortField: 'obj_label',
            cell: (row: StatusHistory, index: any, column: any) => (
                <span className="">{row.obj_label}</span>
            )
        },
        {
            name: 'Usuario',
            selector: (row: any) => row.user,
            sortable: true,
            sortField: 'user',
            cell: (row: StatusHistory, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: row.user ? row.user : '' }}
                ></span>
            )
        },
        {
            name: 'Registro creado',
            selector: (row: any) => row.created_at,
            sortable: true,
            sortField: 'created_at',
            cell: (row: StatusHistory, index: any, column: any) => (
                <span className="">{momentParseDateTime(row.created_at)}</span>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <StatusHistoryFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={statusHistories}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default StatusHistoryDataTable;
