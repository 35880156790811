import { DefaultGroupInt, Group } from "./Group";

export interface Column {
    id?: number;
    column_name?: string;
    table_name?: string;
    alias?: string;
    table_url?: string;
}

export interface ColumnGroupDataForm {
    group: number,
    columns: Column[]
}

export const defaultColumnGroupDataForm: ColumnGroupDataForm = {
    group: -1,
    columns: [],
};