import { useEffect } from "react";
import { Column, ColumnGroupDataForm } from "../../../app/models/Column";
import useHandleErrorFields from "../../../hooks/useHandleErrorFields";
import { Group } from "../../../app/models/Group";
import SelectTwo, { SelectTwoMapperOptions } from "../../../components/SelectTwo";


interface GeneralColumnGroupProps {
    columnGroupForm: ColumnGroupDataForm;
    setColumnGroupForm: (truck: ColumnGroupDataForm) => void;
    columns: Column[];
    groups: Group[];
    errorFields?: any;
}

const ColumnGroupForm = ({
    columnGroupForm,
    setColumnGroupForm,
    columns,
    groups,
    errorFields
}: GeneralColumnGroupProps) => {

    const { setErrors, fieldErrorMessage, fieldHasError, onFocusRemove } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };
        setColumnGroupForm({ ...columnGroupForm, ...obj });
    };

    const MapperOptions = (items: any[]) => {
        return items.map((item) => ({
            value: item.id,
            label: item.alias
        }));
    };

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Rol</label>
                    <SelectTwo
                        name="group"
                        id="group"
                        inputValue={columnGroupForm.group}
                        options={SelectTwoMapperOptions(groups, 'id', ['name'])}
                        hasError={fieldHasError('group') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('group', value.value)
                        }
                        placeholder={'Seleccione tipo de gasto'}
                        onFocus={() => onFocusRemove('group')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('group')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                <label className="col-form-label">Columnas</label>
                    <SelectTwo
                        name="columns"
                        id="columns"
                        inputValue={columnGroupForm.columns}
                        options={MapperOptions(columns)}
                        hasError={fieldHasError('columns') !== ''}
                        onChange={(value: any) =>
                            setColumnGroupForm({
                                ...columnGroupForm,
                                columns: value
                            })
                        }
                        isSortable={true}
                        isMulti={true}
                        placeholder={'Seleccione las columnas'}
                        onFocus={() => onFocusRemove('columns')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('columns')}
                    </div>
                </div>
            </div>
        </>
    )

}

export default ColumnGroupForm;