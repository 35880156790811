import { Liquidation, LiquidationFilters } from '../../../../app/models/Liquidation';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { formatNumberWithDots } from '../../../../helpers';
import circleRed from '../../../../assets/images/icons/circle-color-red.svg';
import circleGreen from '../../../../assets/images/icons/circle-color-green.svg';
import circleGrey from '../../../../assets/images/icons/circle-color-grey.svg';

interface Props {
    liquidations: Liquidation[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number, name: string) => void;
    approved?: (id: number, value: boolean, message: string) => void;
    documentLiquidation?: (id:number) => void
    filter: LiquidationFilters;
    setFilter: (filter: LiquidationFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
}

const LiquidationDataTable = ({
    liquidations,
    totalRows,
    loading = false,
    edit,
    active,
    show,
    destroy,
    approved,
    documentLiquidation,
    filter,
    setFilter,
    callbackSuccess
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: Liquidation, index: any, column: any) => (
                <span className="">
                    <img
                        src={
                            row.state == 'Borrador'
                                ? circleGrey
                                : row.state == 'Confirmado'
                                ? circleGreen 
                                : circleRed
                        }
                        alt="circle"
                        style={{
                            width: '25px',
                            height: '25px',
                            marginRight: '5px',
                            marginBottom: '3px'
                        }}
                    />
                    <span className="">{row.id}</span>
                </span>
            )
        },
        {
            name: 'Trabajador',
            selector: (row: any) => row.worker_name,
            sortable: false,
            sortField: 'worker_name',
            cell: (row: any, index: any, column: any) => <span className="">{row.worker_name}</span>
        },
        {
            name: 'Periodo',
            selector: (row: any) => row.period,
            sortable: true,
            sortField: 'period',
            cell: (row: any, index: any, column: any) => <span className="">{row.period}</span>
        },
        {
            name: 'Salario base',
            selector: (row: any) => row.base_salary,
            sortable: true,
            sortField: 'base_salary',
            cell: (row: any, index: any, column: any) => (
                <span className="">{formatNumberWithDots(row.base_salary) + ` CLP`}</span>
            )
        },
        {
            name: 'Días trabajados',
            selector: (row: any) => row.workdays,
            sortable: true,
            sortField: 'workdays',
            cell: (row: any, index: any, column: any) => <span className="">{row.workdays}</span>
        },
        {
            name: 'Salario neto',
            selector: (row: any) => row.net_salary,
            sortable: true,
            sortField: 'net_salary',
            cell: (row: any, index: any, column: any) => (
                <span className="">{formatNumberWithDots(row.net_salary) + ` CLP`}</span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row.id,
            sortable: false,
            cell: (row: any, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {documentLiquidation && (
                        <ButtonTableAction
                            callbackFunction={() => documentLiquidation(row.id ?? -1)}
                            classIcon={'mdi mdi-download'}
                            colorIcon={'text-success'}
                            errorMessage={'No se puede descargar este registro.'}
                            title={'Descargar'}
                        />
                    )}
                    {approved  && (
                            <>
                                <ButtonTableAction
                                    callbackFunction={() =>
                                        approved(row?.id ?? -1, true, 'aprobado')
                                    }
                                    classIcon={'mdi mdi-checkbox-marked-circle'}
                                    colorIcon={'text-info'}
                                    errorMessage={'No se puede aprobar este registro.'}
                                    title={'Aprobar'}
                                />
                                <ButtonTableAction
                                    callbackFunction={() =>
                                        approved(row?.id ?? -1, false, 'rechazado')
                                    }
                                    classIcon={'mdi mdi-close-circle'}
                                    colorIcon={'text-danger'}
                                    errorMessage={'No se puede rechazar este registro.'}
                                    title={'Rechazar'}
                                />
                            </>
                        )}
                </div>
            )
        }
    ];

    return (
        <>
            <DefaultDataTable
                columns={columns}
                data={liquidations}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default LiquidationDataTable;
