import React, { useContext } from 'react';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { QuoteStatus } from '../../../../app/models/QuoteStatus';
import { momentParseDateTime } from '../../../../helpers';
import {
    StatusHistory,
    StatusHistoryCode,
    StatusHistoryCodeFilters,
    StatusHistoryFilters
} from '../../../../app/models/StatusHistory';
import StatusHistoryFilter from './StatusHistoryFilter';
import StatusHistoryCodeFilter from './StatusHistoryCodeFilter';

interface Props {
    statusHistoryCodes: StatusHistoryCode[];
    totalRows: number;
    loading?: boolean;
    filter: StatusHistoryCodeFilters;
    setFilter: (filter: StatusHistoryCodeFilters) => void;
    paginationRowsPerPageOptions?: any[];
    onSearch: () => void;
}

const StatusHistoryCodesDataTable = ({
    statusHistoryCodes,
    totalRows,
    filter,
    setFilter,
    loading = false,
    onSearch
}: Props) => {
    const columns = [
        {
            name: 'Código',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: StatusHistoryCode, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Estado',
            selector: (row: any) => row.name,
            sortable: true,
            sortField: 'name',
            cell: (row: StatusHistoryCode, index: any, column: any) => (
                <span className="">{row.name}</span>
            )
        },
        {
            name: 'Item',
            selector: (row: any) => row.object_name,
            sortable: true,
            sortField: 'object_name',
            cell: (row: StatusHistoryCode, index: any, column: any) => (
                <span className="">{row.object_name}</span>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <StatusHistoryCodeFilter filter={filter} setFilter={setFilter} onSearch={onSearch}/>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={statusHistoryCodes}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default StatusHistoryCodesDataTable;
