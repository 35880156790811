import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { ColumnGroupDataForm } from '../../models/Column';


const useColumnService = () => {

    const { doGet, doPost } = useFetch();

    const [fetchingGetColumnsByTable, setFetchingGetColumnsByTable] = useState(false);
    const [fetchingGetColumnsByTableAndRole, setFetchingGetColumnsByTableAndRole] = useState(false);
    const [fetchingStoreColumnGroup, setFetchingStoreColumnGroup] = useState(false);
    const [fetchingCreateColumnGroup, setFetchingColumnGroup] = useState(false);

    const getColumnsByTable = (tableName: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.COLUMNS.GET_COLUMNS_BY_TABLE.replace(':table-name', tableName),
            setFetching: setFetchingGetColumnsByTable
        })
    };

    const createColumnGroup = (tableName: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.COLUMNS.CREATE_COLUMN_GROUP.replace(':table-name', tableName),
            setFetching: setFetchingColumnGroup
        });
    };

    const storeColumnGroup = (columnGroup: ColumnGroupDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.COLUMNS.STORE_COLUMN_GROUP,
            body: columnGroup,
            setFetching: setFetchingStoreColumnGroup
        });
    };

    const getColumnsByTableAndRole = (tableName: string, rolId: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.COLUMNS.GET_COLUMNS_BY_TABLE_AND_ROLE.replace(':table-name', tableName).replace(':rol-id', rolId.toString()),
            setFetching: setFetchingGetColumnsByTableAndRole
        })
    };

    return {
        fetchingGetColumnsByTable,
        fetchingGetColumnsByTableAndRole,
        fetchingStoreColumnGroup,
        fetchingCreateColumnGroup,
        getColumnsByTable,
        getColumnsByTableAndRole,
        storeColumnGroup,
        createColumnGroup
    };
};

export default useColumnService;

