import React from 'react';
import MonthYearPicker from '../../../../components/inputs/MonthYearPicker';
import { momentParseDate } from '../../../../helpers';
import { StatusHistoryFilters } from '../../../../app/models/StatusHistory';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: StatusHistoryFilters;
    setFilter: (filter: StatusHistoryFilters) => void;
    onSearch: () => void;
};

const StatusHistoryFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: { target: { name: string; value: string } }) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="Item">
                            Item
                        </label>
                        <input
                            title="Item"
                            type="text"
                            id="obj_label"
                            name="obj_label"
                            className="form-control form-control-sm"
                            value={filter.obj_label}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="created_date_first">
                            Fecha desde
                        </label>
                        <input
                            title="Fecha desde"
                            type="date"
                            id="created_date_first"
                            name="created_date_first"
                            className="form-control form-control-sm"
                            value={filter.created_date_first ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-md-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="created_date_last">
                            Fecha hasta
                        </label>
                        <input
                            title="Fecha hasta"
                            type="date"
                            id="created_date_last"
                            name="created_date_last"
                            className="form-control form-control-sm"
                            value={filter.created_date_last ?? ''}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatusHistoryFilter;
